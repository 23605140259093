body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100vh;
  background-color: rgb(244, 246, 246) !important;
}

html, body, #root {
  height: 100%
}

tbody + thead{
  display: none;
}

div.dataTables_filter label{
  font-size: 0;
}

.leaflet-div-icon-numbered {
  background: transparent;
  border: none;
}

.leaflet-marker-icon .number {
  position: relative;
  top: -43px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: dimgray;
  font-family: 'Asap';
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup {
  margin-bottom: 20px !important;
}

.bcw-legend {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  color: #2e2e2e;
}

.bcw-legend > h2 {
  margin: 0;
  margin-bottom: 5px;
}

.bcw-legend-element {
  display: -webkit-box;
}

.bcw-legend-del-kml {
  cursor: pointer;
}

.bcw-legend-box {
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-right: 5px;
  margin-top: 3px;
}

.bcw-legend-box-uncheck {
  background-color: white !important; 
}

/* fix image previewer styles */
div.image-gallery div.image-gallery-image {
  background-color: black;
}

div.image-gallery div.image-gallery-image img {
  height: 50vh;
  object-fit: contain;
}

div.image-gallery.fullscreen-modal {
  height: 100vh;
}

div.image-gallery.fullscreen-modal div.image-gallery-image img {
  height: 91vh;
}

div.image-gallery div.image-gallery-thumbnails {
  background-color: black;
}

div.image-gallery a.image-gallery-thumbnail.active {
  border: 4px solid white;
}

div.image-gallery span.image-gallery-thumbnail-inner {
  height: 7vh;
}

div.image-gallery span.image-gallery-thumbnail-inner img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

div.image-gallery.image-species-container span.image-gallery-description {
  font-style: italic;
}

div.image-gallery button {
  outline: none;
}

.nv-legend-small {
  transform: scale(0.9);
}

/* center map icons in toolbar */
.easy-button-button .button-state {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.easy-button-button .button-state svg {
  margin: 0px !important;
}

/* íconos para dibujar */
.leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top {
  border: none;
  cursor: grab;
}

.leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top a {
  border: 2px solid rgba(0,0,0,0.2);
  width: 34px;
  height: 34px;
  border-radius: 6px !important;
}

.leaflet-touch .leaflet-draw-actions {
 margin-top: 3px !important;
}

.leaflet-draw-actions a {
  background-color: rgba(0,0,0,0.9) !important;
  color: #FFF !important;
  border: 1px solid rgba(196,196,196, 1);
}

.leaflet-draw-actions a:hover{
  background-color: rgba(64, 71, 75, 1) !important;
}

div#simple-tabpanel-0 .leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top a {
  width: 30px;
  height: 30px;
}